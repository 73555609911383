import React from "react";
import theme from "theme";
import { Theme, Image, Section, Text, Link, Strong, Box } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Дизайнер Артём Жигалин
			</title>
			<meta name={"description"} content={"Делаю вдумчивый выразительный и работающий дизайн. Мой фокус это SaaS-проекты"} />
			<meta property={"og:title"} content={"Дизайнер Артём Жигалин"} />
			<meta property={"og:description"} content={"Делаю вдумчивый выразительный и работающий дизайн. Мой фокус это SaaS-проекты"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-512.png?v=2022-06-20T14:58:50.557Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-32.png?v=2022-06-18T12:04:38.725Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-152.png?v=2022-06-18T12:04:53.625Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-152.png?v=2022-06-18T12:04:53.625Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-152.png?v=2022-06-18T12:04:53.625Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-152.png?v=2022-06-18T12:04:53.625Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/az-logo-270.png?v=2022-06-18T12:05:00.760Z"} />
		</Helmet>
		<Box
			min-width="100px"
			border-width="22px"
			border-style="solid"
			border-color="--color-lightD2"
			display="flex"
			flex-direction="column"
			padding="12px 24px 24px 24px"
			md-border-width="0px"
			sm-padding="12px 0px 24px 0px"
			align-items="center"
			justify-content="center"
		>
			<Components.Header>
				<Override slot="quarklycommunityKitMobileSidePanelOverride3" />
				<Override slot="quarklycommunityKitMobileSidePanelOverride1" />
				<Override slot="linkbox1" />
				<Override slot="image1" />
				<Override slot="quarklycommunityKitMobileSidePanel" />
				<Override slot="quarklycommunityKitMobileSidePanelOverride2" />
				<Override slot="link1" />
				<Override slot="link2" />
				<Override slot="link3" />
			</Components.Header>
			<Section>
				<Override slot="SectionContent" align-items="center" sm-align-items="flex-start" />
				<Image
					src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/artem-zhigalin-photo.png?v=2022-06-20T11:09:32.288Z"
					display="block"
					width="100px"
					height="100px"
					border-radius="50%"
				/>
				<Components.TypeAnimation
					margin="20px auto 0px auto"
					font="--lead42"
					text-align="center"
					width="60%"
					md-width="100%"
					color="--darkL2"
					min-height="110px"
					sm-font="--lead28"
					sm-min-height="90px"
					sm-text-align="left"
				/>
				<Components.TypeDesc
					color="--darkL2"
					font="--lead28"
					margin="20px auto 100px auto"
					md-width="100%"
					min-height="80px"
					text-align="center"
					width="60%"
					sm-font="--lead21"
					sm-margin="20px auto 50px auto"
					sm-text-align="left"
				/>
			</Section>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="48px"
				xl-grid-template-columns="1fr"
				max-width="2560px"
				width="100%"
				sm-padding="0px 24px 0px 24px"
			>
				<Text
					margin="0px 0px 26px 0px"
					color="--darkL2"
					font="--headline2"
					width="100%"
					sm-font="--headline3"
					grid-column="1/3"
					xl-grid-column="1/2"
					sm-margin="0px 0px 12px 0px"
					lg-grid-column="auto"
				>
					Портфолио
				</Text>
				<Components.CaseCard
					margin="0px 0 0px 0"
					padding="0px 0px 0px 0px"
					border-color="none"
					border-radius="10px"
					border-width={0}
					background="--color-quarklyBg"
					overflow-y="hidden"
					overflow-x="hidden"
					md-border-radius="10px"
				>
					<Override slot="box2" />
					<Override
						slot="link1"
						href="https://quarkly.io/"
						text-decoration-line="initial"
						target="_blank"
						color="--quarkly"
						border-color="--color-quarkly"
					>
						quarkly.io
					</Override>
					<Override slot="text" color="--quarkly">
						Кваркли
					</Override>
					<Override slot="SectionContent" xl-max-width="100%" background="none" flex-direction="column" />
					<Override slot="text1" color="--darkL2">
						Ноу-код / лоу-код платформа для создания веб-сайтов и веб-приложений. Используйте всю мощь веб-дизайна и создавайте готовые веб-сайты и приложения на Реакт
					</Override>
					<Override slot="button" background="#6CFFE4" color="--darkL2" display="none" />
					<Override slot="text2" color="--grey">
						UX / UI дизайн, продуктовый дизайн, менеджмент
					</Override>
					<Override slot="box" height="auto" flex="1 1 0%" />
					<Override slot="image" display="flex" src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-quarkly-index-image-en-1.png?v=2022-07-18T13:40:48.810Z" />
				</Components.CaseCard>
				<Components.CaseCard
					border-radius="10px"
					background="--color-webaskBg"
					overflow-y="hidden"
					overflow-x="hidden"
					md-border-radius="10px"
				>
					<Override slot="SectionContent" background="none" flex-direction="column" />
					<Override slot="text" color="--webask">
						ВебАск
					</Override>
					<Override slot="text1" color="--darkL2" />
					<Override slot="text2" color="--grey" />
					<Override slot="text3" />
					<Override slot="link1" color="--webask" border-color="--color-webask" />
					<Override slot="image" display="flex" src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-webask-index-image-en-1.png?v=2022-07-18T16:19:56.290Z" />
					<Override slot="button" background="--color-webask" />
					<Override slot="box2" />
					<Override slot="box1" />
					<Override slot="box" height="auto" flex="1 1 0%" />
				</Components.CaseCard>
				<Components.CaseCard
					padding="0px 0px 0px 0px"
					margin="0px 0 0px 0"
					border-radius="10px"
					background="#b9f6ca"
					overflow-y="hidden"
					overflow-x="hidden"
					md-border-radius="10px"
				>
					<Override slot="text" color="--stepform" sm-font="--headline3">
						СтепФОРМ
					</Override>
					<Override slot="SectionContent" background="none" flex-direction="column" />
					<Override slot="text1" color="--darkL2">
						Онлайн конструктор, где вы можете создавать онлайн калькуляторы и другие типы форм для сайтов, мессенджеров и соцсетей
					</Override>
					<Override slot="text2" color="--grey">
						UX / UI дизайн, продуктовый дизайн, менеджмент
					</Override>
					<Override slot="text3" color="white" border-color="white">
						stepform.io
					</Override>
					<Override
						slot="link1"
						href="https://stepform.io/"
						target="_blank"
						text-decoration-line="initial"
						border-color="--color-stepform"
						color="--stepform"
						md-margin="0px 0px 26px 0px"
					>
						stepform.io
					</Override>
					<Override slot="image" display="flex" src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-stepform-index-image-en-1.png?v=2022-07-18T13:55:04.533Z" />
					<Override
						slot="button"
						type="link"
						text-decoration-line="initial"
						href="/portfolio/stepform"
						background="--color-stepform"
					/>
					<Override
						slot="box2"
						md-flex-direction="column"
						md-justify-content="flex-start"
						md-align-items="flex-start"
						md-height="auto"
						md-min-height="auto"
						margin="auto 0px 0px 0px"
					/>
					<Override slot="box1" margin="0px 0px 40px 0px" />
					<Override slot="box" height="auto" flex="1 1 0%" />
				</Components.CaseCard>
				<Components.CaseCard
					padding="0px 0px 0px 0px"
					margin="0px 0 0px 0"
					border-radius="10px"
					border-color="#FECB2F"
					background="#fff9c4"
					overflow-y="hidden"
					overflow-x="hidden"
					md-border-radius="10px"
				>
					<Override slot="text" color="--ukitalt" sm-font="--headline3">
						юКит Альт
					</Override>
					<Override slot="SectionContent" background="none" flex-direction="column" />
					<Override slot="text1" color="--darkL2">
						Дополнение к конструктору uKit, с помощью которого пользователи Facebook и VK могут сделать сайт из своей группы или профиля
					</Override>
					<Override slot="text2" color="--grey">
						UX / UI дизайн, продуктовый дизайн, менеджмент
					</Override>
					<Override slot="text3" color="white" border-color="white" />
					<Override
						slot="button"
						background="--color-ukitalt"
						color="--darkL2"
						display="block"
						type="link"
						text-decoration-line="initial"
						href="/portfolio/ukitalt"
					/>
					<Override
						slot="link1"
						color="--ukitalt"
						text-decoration-line="initial"
						href="https://ukit.com/ru/lp/convert"
						target="_blank"
						border-color="--color-ukitalt"
						md-margin="0px 0px 26px 0px"
					>
						ukit.com/ru/lp/convert
					</Override>
					<Override slot="image" display="flex" src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-alt-mini-builder-website-type-en-1.png?v=2022-07-18T14:43:18.620Z" />
					<Override
						slot="box2"
						md-align-items="flex-start"
						md-height="auto"
						md-min-height="auto"
						md-flex-direction="column"
						md-justify-content="flex-start"
					/>
					<Override slot="box1" />
					<Override slot="box" height="auto" flex="1 1 0%" />
				</Components.CaseCard>
				<Components.CaseCard
					padding="0px 0px 0px 0px"
					margin="0px 0 0px 0"
					border-radius="10px"
					background="--color-ukitBg"
					overflow-y="hidden"
					overflow-x="hidden"
					md-border-radius="10px"
				>
					<Override slot="text" color="--ukit" sm-font="--headline3">
						юКит
					</Override>
					<Override slot="SectionContent" background="none" flex-direction="column" />
					<Override slot="text1" color="--darkL2">
						Простой конструктор сайтов для бизнеса, где можно делать сайты без специальных навыков
					</Override>
					<Override slot="text2" color="--grey">
						UX / UI дизайн
					</Override>
					<Override slot="text3" color="white" border-color="white" />
					<Override
						slot="button"
						background="--color-ukit"
						color="--light"
						display="block"
						type="link"
						text-decoration-line="initial"
						href="/portfolio/ukit"
					/>
					<Override
						slot="link1"
						color="--ukit"
						text-decoration-line="initial"
						href="https://ukit.com/"
						target="_blank"
						border-color="--color-ukit"
						md-margin="0px 0px 26px 0px"
					>
						ukit.com
					</Override>
					<Override slot="image" display="flex" src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-ukit-index-image-en-1.png?v=2022-07-18T14:17:10.635Z" />
					<Override
						slot="box2"
						md-flex-direction="column"
						md-justify-content="flex-start"
						md-align-items="flex-start"
						md-height="auto"
						md-min-height="auto"
					/>
					<Override slot="box1" />
					<Override slot="box" height="auto" flex="1 1 0%" />
				</Components.CaseCard>
				<Components.CaseCard
					margin="0px 0 0px 0"
					padding="0px 0px 0px 0px"
					border-radius="10px"
					background="#ffe0b2"
					overflow-y="hidden"
					overflow-x="hidden"
					md-border-radius="10px"
				>
					<Override slot="SectionContent" background="none" flex-direction="column" />
					<Override slot="text" color="#f4511e" sm-font="--headline3">
						Мини-проекты
					</Override>
					<Override slot="text1" color="--darkL2">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							<Link
								href="http://nygame.ukit.space/"
								target="_blank"
								color="#f4511e"
								text-decoration-line="initial"
								border-width="0 0 1px 0"
								border-style="solid"
								border-color="#f4511e"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								Дискаунт хантер
							</Link>
						</Strong>
						{" "}— развлекательная игра для пользователей юКит.
						<br />
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							<Link
								href="https://bigmaconomics.com/"
								border-width="0 0 1px 0"
								border-style="solid"
								border-color="#f4511e"
								text-decoration-line="initial"
								color="#f4511e"
								target="_blank"
							>
								Бигмакономикс
							</Link>
						</Strong>
						{" "}— шуточное мини-приложение для расчета количества бигмаков на зарплату.
					</Override>
					<Override slot="text2" color="--grey">
						UI дизайн, иллюстрации, менеджмент, концепция
					</Override>
					<Override slot="text3" color="white" border-color="white">
						ukit.com
					</Override>
					<Override
						slot="button"
						background="#f4511e"
						color="white"
						margin="auto 0px 0px auto"
						font="normal 400 20px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
						type="link"
						text-decoration-line="initial"
						href="/portfolio/mini-projects"
						display="block"
					>
						Посмотреть →
					</Override>
					<Override
						slot="link1"
						color="#FFF"
						text-decoration-line="initial"
						target="_blank"
						href="https://ukit.com/"
						border-color="#FFF"
						display="none"
					>
						ukit.com
					</Override>
					<Override slot="image" display="flex" src="https://uploads.quarkly.io/622f01726ba4ec00186af2ff/images/portfolio-mini-projects-index-image-en-1.png?v=2022-07-18T14:54:22.712Z" />
					<Override slot="box1" color="--light" />
					<Override slot="box2" />
					<Override slot="box" height="auto" flex="1 1 0%" />
				</Components.CaseCard>
			</Box>
			<Components.Footer sm-padding="0px 24px 0px 24px" margin="36px 0 0 0">
				<Override slot="socialMediaOverride1" />
				<Override slot="socialMediaOverride" />
				<Override slot="SectionContent" />
			</Components.Footer>
		</Box>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"622f01726ba4ec00186af2fd"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
			<script type={"text/javascript"} place={"endOfHead"} rawKey={"62b188e99e03ae0e7196a484"}>
				{"   (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};\n   m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})\n   (window, document, \"script\", \"https://mc.yandex.ru/metrika/tag.js\", \"ym\");\n\n   ym(52528996, \"init\", {\n        clickmap:true,\n        trackLinks:true,\n        accurateTrackBounce:true\n   });"}
			</script>
			<script async={false} src={"https://www.googletagmanager.com/gtag/js?id=G-5FMRH52STD"} place={"endOfHead"} rawKey={"62d57fa6534ed0228e31f79a"} />
			<script place={"endOfHead"} rawKey={"62d57fb994ee59fbdc7b583c"}>
				{"  window.dataLayer = window.dataLayer || [];\n  function gtag(){dataLayer.push(arguments);}\n  gtag('js', new Date());\n\n  gtag('config', 'G-5FMRH52STD');"}
			</script>
		</RawHtml>
	</Theme>;
});